<template>
  <div>
    <!-- 通过姓名查找 -->
    <!-- <by-name-view></by-name-view> -->
    <router-view></router-view>
  </div>
</template>

<script>
// import ByNameView from './views/search/ByNameView.vue'
export default {
  components: {  },
  data () {
    return {
      message:"hello vue!"
    }
  }
}
</script>

<style>
</style>
