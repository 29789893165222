import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [{
    path: '/stuSearch',
    name: 'stuSearch',
    component: () => import('../views/search/StuSearchView.vue')
  },
  {
    path: '/byName',
    redirect: '/stuSearch'
  },{
    path: '/updateLog',
    name: 'updateLog',
    component: () => import('../views/search/UpdateLogView.vue')
  },{
    path: '/dataChan',
    name: 'dataChan',
    component: () => import('../views/search/DataChanView.vue')
  },{
    path: '/waterCard',
    name: 'waterCard',
    component: () => import('../views/search/WaterCardView.vue')
  },
  {
    path: '/loginSuccess', // 回调路由
    name: 'loginSuccess',
    component: () => import('../views/LoginSuccess.vue'), // 处理回调的组件
  },{
    path: '/',
    redirect: '/stuSearch'
  }
]

const router = new VueRouter({
  routes
})

export default router