import Vue from 'vue'
import App from './App.vue'
import router from './router'
//引入element-ui
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
//引入axios
import axios from 'axios';

Vue.config.productionTip = false
Vue.use(ElementUI);

// 设置基础URL
//axios.defaults.baseURL = 'http://127.0.0.1:8085';
axios.defaults.baseURL = 'https://api.mikotodev.cn';

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
